
.dropdown-toggle{
  &:after{
    display: none;
  }
}

.card-table-options{
  tr {
    td,
    th {
      &:last-child {
        //padding: 0 !important;
        padding-right: $table-cell-padding-x;
        border-right: 0;
      }
    }
  }

  .dropdown-toggle.btn{
    display: inline-flex;
  }
}


.btn {
  display: inline-block;
}

.dropdown-item {
  display: inline-block;
}

.nav-link {
  display: inline-block;
}
