.dl-horizontal{
  margin: 0;
  border-bottom: 1px dashed #ccc;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "label" "value";
  padding: .5rem 0;
  gap: 0.25rem;

  &:hover{
    background-color: $hover-bg;
  }

  dt {
    font-weight: 600;
    margin: 0;
    grid-area: label;
  }

  dd {
    margin: 0;
    grid-area: value;
  }

  @include media-breakpoint-up(sm) {
    grid-template-columns: 160px 1fr;
    grid-template-areas: "label value";
    gap: 1rem;

    dt {
      text-align: right;
    }
  }
}


/* Timeline */
.timeline {	position: relative; }

.timeline-list {
  list-style: none;
  margin: 0;
  padding: 0;

  &:after {
    content: " ";
    background-color: $border-color;
    position: absolute;
    display: block;
    width: 2px;
    top: 20px;
    left: 130px;
    bottom: 0;
    z-index: 1;
  }

  .timeline-item {
    position: relative;
    margin: 0;
    padding: 1rem 0;
    &:hover {
      background-color: $hover-bg;
    }

  }
}

.timeline-icon {
  background: $primary;
  color: $white;
  border-radius: 50%;
  position: absolute;
  width: 32px;
  height: 32px;
  //line-height: 28px;
  //font-size: $font-size-base + 1;
  text-align: center;
  left: 115px;
  top: 10px;
  z-index: 100;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .875rem;

  > img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-top: -2px;
    margin-left: -2px;
    border: 2px solid $primary;
  }
}

.timeline-time {
  float: left;
  width: 95px;
  text-align: right;
  font-size: 0.75rem;
  font-style: italic;
  white-space: pre-wrap;
}

.timeline-content {
  margin-left: 163px;
  > div {
    padding-bottom: 5px;
  }

  .timeline-note{
    white-space: pre-wrap;
    padding: 3px 10px;
    background-color: #f9f9f9;
  }
}

