.page:has(.app-simple-layout){
  background-color: $gray-50;
}

.app-layout-loader {
  position: fixed;
  font-size: 0.8125rem;
  margin: auto;
  top: 0;
  z-index: 10000;
  //background-color: tint-color($yellow, 90%);
  //color: $body-color;
  background-color: #fcf8e3;
  border: 1px solid #dbab57;
  border-top: 0;
  width: 200px;
  left: calc(50% - 100px);
  padding: 2px 3px;
  text-align: center;
}