.processImages{
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
}

.processImage{
  width: 100%;
  padding: 2px;
  cursor: pointer;
  aspect-ratio: 16/10;
  border: 1px solid transparent;

  &:hover{
    border-color: #ccc;
  }

  img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}