

$body-bg: #fff !default;
$body-color: #333;

$pink: #FF008C !default;

$link-color:       $body-color !default;
$link-decoration:  none !default;
$link-hover-color:      $pink !default;
$link-hover-decoration: none !default;

$enable-caret: false !default;



